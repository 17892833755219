import { Brand } from 'models/Brand';
import { CountryCode } from 'models/CountryCode';

export const getBrandCountryCode = (brand?: string) => {
  switch (brand) {
    case Brand.FutureCruisePackagesUk:
    case Brand.GuestBookingsUK:
      return CountryCode.UnitedKingdom;
    case Brand.FutureCruisePackagesCanada:
    case Brand.UExperiencesCanada:
      return CountryCode.Canada;
    case Brand.ExploreByPuebloBonitoMexico:
    case Brand.UExperiencesMexico:
      return CountryCode.Mexico;
    default:
      return CountryCode.UnitedStates;
  }
};

export const getPartnerLogoPath = (partnerKey?: string) => {
  let path;
  try {
    path = `/images/logos/logo-${partnerKey}.png`;
  } catch (err) {
    console.error(`Brand logo path is missing for partner: ${partnerKey}`);
    path = '/images/logos/logo-ONE.png';
  }
  return path;
};
