export enum AlternativeCurrency {
  MedallionClassRewards = 'MCR',
  Rewards = 'R',
  GuestBookingsUKRewards = 'RWU',
  Points = 'CVCP',
  OMCPoints = 'P',
  CruisePoints = 'CP',
  PrincessPromoPoints = 'PPP',
  PrincessPartnerPoints = 'PPP',
  HotelCredits = 'HC',
  FutureCruiseCredits = 'PFCC',
  HotelCreditsCanada = 'HCN',
  HotelCreditsUK = 'HCU',
  MagicStars = 'MS',
  GPRMarketPoints = 'GMP',
  Credits = 'C',
  RewardCredits = 'GWR',
  CruiseComCredits = 'CCC',
  TravelRewards = 'VTR',
  CUGTravelRewards = 'PTR',
  ElCidRewardPoints = 'ECR',
  ElCidPoints = 'EP',
  UVCRewards = 'UVCR',
  UVCPoints = 'UP',
  TravelGuestBookingsRewards = 'TGBC',
  ExploreRewards = 'ERC',
  ExploreMexicoRewards = 'ERCM',
  UXMRewards = 'UXMR',
  UXMPoints = 'UXMP',
  UXCRewards = 'UXCAR',
  UXCPoints = 'UXCAP',
  ASPRewards = 'ASPR',
  ASPPoints = 'ASPP',
  CRPSavingRewards = 'SR',
  LRSShoppingCredits = 'SC',
  LRSWineCredits = 'WC',
}
