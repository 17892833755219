import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';

import { useAgentAccess } from 'components/hooks/useAgentAccess';
import { useAppHelpers } from 'components/hooks/useAppHelpers';
import { AgencyHeaderSelect } from 'components/layout/AgencyHeaderSelect';
import { LayoutConfig } from 'components/layout/LayoutConfig';
import { BrandHeaderSelect, PartnerHeaderSelect } from 'components/layout/PartnerHeaderSelect';
import { AppRoute, buildPath, routes } from 'Routes';
import { TreeItem } from 'styled';
import { getMatchRoute } from 'utils/routesUtils';

import { BackOfficeLogo } from './BackOfficeLogo';

interface AppTreeMenuProps {
  testId: string;
  config?: LayoutConfig;
  disablePartnerSelect: boolean;
  handleDrawerClose: () => void;
}

interface MenuTreeItemProps {
  itemId: string;
  label: string;
  icon?: JSX.Element;
  isSelected: boolean;
  onClick: () => void;
  children?: React.ReactNode;
  testId: string;
  sx?: SystemStyleObject;
}

const MenuTreeItem = ({ itemId, label, icon, children, isSelected, sx, onClick, testId }: MenuTreeItemProps) => {
  return (
    <TreeItem
      data-testid={testId}
      itemId={itemId}
      onClick={onClick}
      sx={sx}
      label={
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              width: '25px',
              mr: 1,
              svg: { stroke: isSelected ? theme.customPalette.icons.accent : theme.customPalette.icons.main },
            })}
          >
            {icon}
          </Box>
          <Typography
            sx={(theme) => ({
              color: isSelected ? theme.palette.primary.main : 'initial',
              fontWeight: isSelected ? 600 : theme.typography.fontWeightRegular,
            })}
          >
            {label}
          </Typography>
        </Box>
      }
    >
      {children}
    </TreeItem>
  );
};

export const SideTreeMenu = ({ handleDrawerClose, testId, config, disablePartnerSelect }: AppTreeMenuProps) => {
  const testIdPrefix = 'Sidebar';
  const { hasPermission } = useAppHelpers();
  const { agentAccessRequiredBrandConfiguration } = useAgentAccess();
  const navigate = useNavigate();
  const location = useLocation();
  const [matchedPath, setMatchedPath] = useState(getMatchRoute(location));
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    setMatchedPath(getMatchRoute(location));
  }, [location]);

  const hasRoutePermission = (route: AppRoute) => {
    return route?.permissions == null || hasPermission(route.permissions);
  };

  const handleOnClick = (route: AppRoute) => {
    if (!route.insideElements || route.layoutConfig?.isSidebarLinkEnabled) {
      navigate(buildPath(route));
      if (
        smallScreen &&
        (!route.insideElements || !route.insideElements?.some((subRoute) => subRoute.layoutConfig?.showInSidebar))
      ) {
        handleDrawerClose();
      }
    }
  };

  const getSubmenuItem = (route: AppRoute) => {
    return route?.insideElements?.filter((r) => r.layoutConfig?.showInSidebar && hasRoutePermission(r));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <Box>
        <Box py={3} px={1}>
          {config?.showPartnerSelect ? (
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }} data-testid={`${testId}HeaderSwitcher`}>
              <PartnerHeaderSelect disabled={!!disablePartnerSelect} testId={testId} />
              <BrandHeaderSelect disabled={!!disablePartnerSelect} testId={testId} />
            </Box>
          ) : (
            <BackOfficeLogo />
          )}
        </Box>
        <SimpleTreeView sx={{ pb: 2, pl: 0 }}>
          {routes
            .filter(
              (r) =>
                r.layoutConfig?.showInSidebar &&
                hasRoutePermission(r) &&
                (r.insideElements == null || r.insideElements.find((c) => hasRoutePermission(c))),
            )
            .map((route) => {
              const insideElements = getSubmenuItem(route);
              return (
                <MenuTreeItem
                  itemId={route.key}
                  key={route.key}
                  label={route.label}
                  icon={route.icon}
                  isSelected={route.path === matchedPath?.path}
                  sx={{
                    mb: route.layoutConfig?.addMenuSpacing ? 1.75 : 0,
                  }}
                  onClick={() => handleOnClick(route)}
                  testId={`${testIdPrefix}${route.label.replace(' ', '')}MenuItem`}
                >
                  {insideElements?.map((subRoute, index) => (
                    <MenuTreeItem
                      key={subRoute.key}
                      itemId={subRoute.key}
                      label={subRoute.label}
                      isSelected={subRoute.path === matchedPath?.path}
                      sx={{
                        pt: index === 0 ? 0.25 : 0,
                      }}
                      onClick={() => handleOnClick(subRoute)}
                      testId={`${testIdPrefix}${subRoute.label.replace(' ', '')}SubmenuItem`}
                    ></MenuTreeItem>
                  ))}
                </MenuTreeItem>
              );
            })}
        </SimpleTreeView>
      </Box>
      {config?.showAgencySelect && agentAccessRequiredBrandConfiguration('restrictAgencySelect') && (
        <AgencyHeaderSelect autoFocus={config?.focusAgencySelect} testId={testId} />
      )}
    </Box>
  );
};
