import { useState } from 'react';

import { Box } from '@mui/material';
import { Order } from '@one/api-models/lib/Admin/Sales/Order';
import { CruiseOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Cruise/CruiseOrderItemSummary';
import { EditTraveler } from '@one/api-models/lib/Admin/Sales/Travel/Cruise/EditTraveler';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import { ReactComponent as RefundIcon } from 'assets/icons/refund.svg';
import { useAppHelpers } from 'components/hooks/useAppHelpers';
import { TakePaymentDialog } from 'components/views/paymentPlans/TakePaymentDialog';
import { Button, Menu, MenuItem } from 'styled';
import { ActionIconButton } from 'styled/IconButton';

import { CancelOrderDialog } from './CancelOrderDialog';
import { EditTravelersSection } from './EditTravelersSection';

interface HeaderActionsProps {
  orderData: Order;
  testId: string;
  handleRefetchOrder: (refetchAllData?: boolean) => void;
}
export const HeaderActions = ({ orderData, testId, handleRefetchOrder }: HeaderActionsProps) => {
  const { hasPermission } = useAppHelpers();
  const [isCancellationOpen, setIsCancellationOpen] = useState<boolean>(false);
  const [isEditTravelersOpen, setIsEditTravelersOpen] = useState<boolean>(false);
  const [isTakePaymentOpen, setIsTakePaymentOpen] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const cruiseOrderItem = orderData?.orderItems.find(
    (orderItem) => orderItem.$type === CruiseOrderItemSummary.$type,
  ) as CruiseOrderItemSummary | undefined;
  const supportedEditOperations =
    cruiseOrderItem && cruiseOrderItem?.supportedEditOperations ? cruiseOrderItem?.supportedEditOperations : [];
  const editTravelerSupportedEditOperation = supportedEditOperations.find((op) => op.$type === EditTraveler.$type);
  const isCancelEnabled = hasPermission(['Sales.Orders:Cancel']) && orderData?.isCancellable === true;
  const isEditTravelersEnabled =
    hasPermission(['Sales.Orders:Edit']) && editTravelerSupportedEditOperation != null && cruiseOrderItem != null;

  const handleCloseEditTravelersForm = (): void => {
    setIsEditTravelersOpen(false);
  };

  const handleCloseCancellationForm = (): void => {
    setIsCancellationOpen(false);
  };

  return (
    <Box>
      {isCancelEnabled && (
        <Button
          variant="outlined"
          onClick={() => setIsCancellationOpen(true)}
          data-testid={`${testId}CancelOrderButton`}
          startIcon={<RefundIcon />}
        >
          Cancel order
        </Button>
      )}

      {isEditTravelersEnabled && (
        <ActionIconButton onClick={handleMenu} sx={{ ml: 1 }}>
          <MoreIcon />
        </ActionIconButton>
      )}

      <Menu
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        elevation={3}
      >
        <MenuItem data-testid={`${testId}EditTravelersButton`} onClick={() => setIsEditTravelersOpen(true)}>
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
            <EditIcon />
          </Box>
          Edit Travelers
        </MenuItem>
      </Menu>

      {/* {orderData.balance.amount > 0 && (
        <Button
          variant="contained"
          onClick={() => setIsTakePaymentOpen(true)}
          data-testid={`${testId}TakePaymentButton`}
          startIcon={<AddIcon />}
          sx={{ ml: 1 }}
        >
          Take a Payment
        </Button>
      )} */}

      {orderData && (
        <>
          {isCancelEnabled && (
            <CancelOrderDialog
              order={orderData}
              testId={testId}
              handleClose={handleCloseCancellationForm}
              onOrderCancellationFinished={() => handleRefetchOrder(true)}
              isCancellationOpen={isCancellationOpen}
            />
          )}

          {isEditTravelersEnabled && (
            <EditTravelersSection
              orderNumber={orderData.orderNumber}
              cruiseOrderItem={cruiseOrderItem}
              editTravelerSupportedEditOperation={editTravelerSupportedEditOperation}
              testId={testId}
              handleCloseEditTravelersForm={handleCloseEditTravelersForm}
              handleOnTravelersEditFinished={() => handleRefetchOrder(false)}
              isEditTravelersOpen={isEditTravelersOpen}
            />
          )}
        </>
      )}
      {isTakePaymentOpen && (
        <TakePaymentDialog
          open={isTakePaymentOpen}
          handleClose={() => setIsTakePaymentOpen(false)}
          testIdPrefix={testId}
          balance={orderData.balance}
          memberId={orderData.customer.memberKey}
        />
      )}
    </Box>
  );
};
