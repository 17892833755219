import { Box, Typography } from '@mui/material';

import { ReactComponent as Logo } from 'assets/icons/backOfficeLogo.svg';

export const BackOfficeLogo = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mb: 6, // avoiding layout shift when showing/hiding partner switcher
        gap: 1,
      }}
    >
      <Logo width={35} />
      <Typography sx={{ fontSize: '0.875rem' }}>Backoffice</Typography>
    </Box>
  );
};
