import { ExportCriteria as AssetsAndLiabilitiesExportCriteria } from '@one/api-models/lib/Admin/Reports/AssetsAndLiabilities/ExportCriteria';
import { ExportCriteria as CustomerReportExportCriteria } from '@one/api-models/lib/Admin/Reports/CustomerReport/ExportCriteria';
import { ExportCriteria as DelinquencyReportExportCriteria } from '@one/api-models/lib/Admin/Reports/DelinquencyReport/ExportCriteria';
import { ExportCriteria as MarketingReportExportCriteria } from '@one/api-models/lib/Admin/Reports/MarketingReport/ExportCriteria';
import { ExportCriteria as SalesReportExportCriteria } from '@one/api-models/lib/Admin/Reports/SalesReport/ExportCriteria';
import { ReportCriteria as SalesReportCriteria } from '@one/api-models/lib/Admin/Reports/SalesReport/ReportCriteria';
import { ReportResponse as SalesReportResponse } from '@one/api-models/lib/Admin/Reports/SalesReport/ReportResponse';
import { ExportCriteria as TransactionReportExportCriteria } from '@one/api-models/lib/Admin/Reports/TransactionsReport/ExportCriteria';

import { FileResponse, IApiClient } from 'apiAccess/api-client';

export interface IReportsAccess {
  salesReportLoad(criteria: SalesReportCriteria): Promise<SalesReportResponse>;
  salesReportExport(criteria: SalesReportExportCriteria): Promise<FileResponse>;
  customerReportExport(criteria: CustomerReportExportCriteria): Promise<FileResponse>;
  marketingReportExport(criteria: MarketingReportExportCriteria): Promise<FileResponse>;
  transactionReportExport(criteria: TransactionReportExportCriteria): Promise<FileResponse>;
  delinquencyReport(criteria: DelinquencyReportExportCriteria): Promise<FileResponse>;
  assetsAndLiabilitiesReport(criteria: AssetsAndLiabilitiesExportCriteria): Promise<FileResponse>;
}

export class ReportsAccess implements IReportsAccess {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  salesReportLoad(criteria: SalesReportCriteria): Promise<SalesReportResponse> {
    return this.apiClient.call('admin/reports/finalizedOrders/load', criteria);
  }

  salesReportExport(criteria: SalesReportExportCriteria): Promise<FileResponse> {
    return this.apiClient.call('admin/reports/finalizedOrders/export', {
      ...criteria,
      $Type: SalesReportExportCriteria.$type,
      cultureCode: 'en-US',
    } as SalesReportExportCriteria);
  }

  customerReportExport(criteria: CustomerReportExportCriteria): Promise<FileResponse> {
    return this.apiClient.call('admin/reports/customerReport/export', {
      ...criteria,
      $Type: CustomerReportExportCriteria.$type,
      cultureCode: 'en-US',
    } as CustomerReportExportCriteria);
  }

  marketingReportExport(criteria: MarketingReportExportCriteria): Promise<FileResponse> {
    return this.apiClient.call('admin/reports/marketingReport/export', {
      ...criteria,
      $Type: MarketingReportExportCriteria.$type,
      cultureCode: 'en-US',
    } as MarketingReportExportCriteria);
  }

  transactionReportExport(criteria: TransactionReportExportCriteria): Promise<FileResponse> {
    return this.apiClient.call('admin/reports/transactionsReport/export', {
      ...criteria,
      $Type: TransactionReportExportCriteria.$type,
      cultureCode: 'en-US',
    } as TransactionReportExportCriteria);
  }
  delinquencyReport(criteria: DelinquencyReportExportCriteria): Promise<FileResponse> {
    return this.apiClient.call('admin/reports/delinquencyReport/export', {
      ...criteria,
      $Type: DelinquencyReportExportCriteria.$type,
      cultureCode: 'en-US',
    } as DelinquencyReportExportCriteria);
  }
  assetsAndLiabilitiesReport(criteria: AssetsAndLiabilitiesExportCriteria): Promise<FileResponse> {
    return this.apiClient.call('admin/reports/assetsAndLiabilitiesReport/export', {
      ...criteria,
      $Type: AssetsAndLiabilitiesExportCriteria.$type,
      cultureCode: 'en-US',
    } as AssetsAndLiabilitiesExportCriteria);
  }
}
