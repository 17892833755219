import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { addMinutes, format } from 'date-fns';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, FormControlLabel, Grid, Link, MenuItem, Paper, Switch, TextField } from '@mui/material';
import { GridPaginationModel } from '@mui/x-data-grid';
import { ExportCriteria } from '@one/api-models/lib/Admin/Reports/SalesReport/ExportCriteria';
import { ReportCriteria } from '@one/api-models/lib/Admin/Reports/SalesReport/ReportCriteria';
import { ReportResponse } from '@one/api-models/lib/Admin/Reports/SalesReport/ReportResponse';

import { ApiError, FileResponse } from 'apiAccess/api-client';
import { ActionButton } from 'components/_common/ActionButton';
import { PageHeader } from 'components/_common/PageHeader';
import UncontrolledDatePicker from 'components/_common/UncontrolledDatePicker';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { DateTypeFilter } from 'models/DateTypeFilter';
import { selectActiveBrand } from 'slices/applicationDataSlice';

import { SalesReportListing } from './SalesReportListing';

export const SalesReport = () => {
  const defaultPageSize = 25;
  const currentDay = new Date();

  const { dateIsValid, getEndDate, getStartDate } = useFormat();
  const { apiErrorHandler } = useToastMessage();
  const { api } = useApiHelpers();
  const { addApiError } = useToastMessage();

  const activeBrand = useSelector(selectActiveBrand);

  const [dateType, setDateType] = useState('created');
  const [startDate, setStartDate] = useState<Date | undefined>(currentDay);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [allBrands, setAllBrands] = useState<boolean>(false);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [isEmptySalesReport, setIsEmptySalesReport] = useState<boolean | null>(null);

  const testIdPrefix = 'SalesReport';

  const linkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    setShowSkeleton(true);
  }, [dateType, startDate, endDate, allBrands, activeBrand]);

  const { data, status, isFetching, isPreviousData } = useQuery<ReportResponse>(
    ['products', page, pageSize, dateType, getStartDate(startDate), getEndDate(endDate), allBrands, activeBrand],
    () => {
      return api.reports.salesReportLoad({
        listingCriteria: {
          skip: page * pageSize,
          take: pageSize,
        },

        ...(dateType === DateTypeFilter.Created && { startDate, endDate }),
        ...(dateType === DateTypeFilter.Updated && {
          lastUpdatedStartDate: getStartDate(startDate),
          lastUpdatedEndDate: getEndDate(endDate),
        }),
        ...(!allBrands && activeBrand && { brandKey: activeBrand.key }),
      } as ReportCriteria);
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      keepPreviousData: true,
      onSuccess: () => {
        setShowSkeleton(false);
      },
      onError: apiErrorHandler,
    },
  );

  useEffect(() => {
    // onSuccess from useQuery is not being called at the initial render
    if (status === 'success') {
      setShowSkeleton(false);
    }
  }, [status]);

  useEffect(() => {
    if (data && data.listingData.items.length === 0) setIsEmptySalesReport(true);
    else setIsEmptySalesReport(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, startDate, endDate, allBrands, activeBrand]);

  const handlePaginationChanges = (data: GridPaginationModel) => {
    setPageSize(data.pageSize);
    setPage(data.page);
  };

  const exportMutation = useMutation<FileResponse, ApiError, ExportCriteria, unknown>(
    async (request: ExportCriteria) => {
      return await api.reports.salesReportExport(request);
    },
    {
      onSuccess: async (response: FileResponse) => {
        if (linkRef === undefined || linkRef === null || linkRef.current === null) return;

        const fileName =
          response.fileName ??
          `SalesReport_UTC${format(addMinutes(new Date(), new Date().getTimezoneOffset()), 'yyyy-MM-dd_HH;mm')}.csv`;

        linkRef.current.href = window.URL.createObjectURL(response.blob);
        linkRef.current.setAttribute('download', fileName);
        linkRef.current.click();
      },
      onError: addApiError,
    },
  );

  const click = (startDate: Date | undefined, endDate: Date | undefined) => {
    exportMutation.mutate({
      brandKey: !allBrands && activeBrand ? activeBrand.key : '',
      ...(dateType === DateTypeFilter.Created && { startDate, endDate }),
      ...(dateType === DateTypeFilter.Updated && { lastUpdatedStartDate: startDate, lastUpdatedEndDate: endDate }),
      startDate: startDate,
      endDate: endDate,
    });
  };

  return (
    <Box>
      <PageHeader title="Sales Report" testId={testIdPrefix} />
      <Paper sx={{ mb: 2, p: 3 }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ flexDirection: { xs: 'column', md: 'row' } }}
        >
          <Grid item xs={12} md="auto">
            <Grid container alignItems="center" sx={{ justifyContent: { xs: 'space-between', md: 'flex-start' } }}>
              <Grid item sx={{ mr: 2 }}>
                <TextField
                  select
                  value={dateType}
                  label="Date Type"
                  name="dateType"
                  onChange={(event) => setDateType(event.target.value)}
                  size="small"
                  sx={{ width: '160px' }}
                  inputProps={{
                    'data-testid': `${testIdPrefix}DateTypeSelectInput`,
                  }}
                >
                  <MenuItem key={1} value={DateTypeFilter.Created}>
                    Created Date
                  </MenuItem>
                  <MenuItem key={2} value={DateTypeFilter.Updated}>
                    Last Update Date
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={5.3} md="auto">
                <UncontrolledDatePicker
                  label="Start Date"
                  placeholder="Start Date"
                  value={startDate}
                  onChange={(newValue) => {
                    if (
                      dateIsValid(newValue?.toLocaleDateString('en-US')) &&
                      startDate?.toLocaleDateString('en-US') !== newValue?.toLocaleDateString('en-US')
                    ) {
                      setStartDate(newValue as SetStateAction<Date | undefined>);
                      if (endDate && newValue && new Date(newValue).getTime() > new Date(endDate).getTime()) {
                        setEndDate(newValue);
                      }
                    }
                  }}
                  maxDate={currentDay}
                  testId={`${testIdPrefix}Start`}
                />
              </Grid>
              <Grid item sx={{ px: 1 }}>
                &#8213;
              </Grid>
              <Grid item xs={5.3} md="auto">
                <UncontrolledDatePicker
                  label="End Date"
                  placeholder="End Date"
                  value={endDate}
                  onChange={(newValue) => {
                    if (
                      dateIsValid(newValue?.toLocaleDateString('en-US')) &&
                      endDate?.toLocaleDateString('en-US') !== newValue?.toLocaleDateString('en-US')
                    ) {
                      setEndDate(newValue as SetStateAction<Date | undefined>);
                      if (startDate && newValue && new Date(newValue).getTime() < new Date(startDate).getTime()) {
                        setStartDate(newValue);
                      }
                    }
                  }}
                  maxDate={currentDay}
                  testId={`${testIdPrefix}End`}
                />
              </Grid>
              <Grid item sx={{ ml: 2 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={allBrands}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAllBrands(event.target.checked)}
                      inputProps={{
                        // eslint-disable-next-line
                        //@ts-ignore
                        'data-testid': `${testIdPrefix}AllBrandsSwitchInput`,
                      }}
                    />
                  }
                  label="All brands"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item justifyContent="center" alignItems="center" sx={{ mt: { xs: 2, md: 0 } }}>
            <ActionButton
              icon={<FileDownloadOutlinedIcon />}
              loading={exportMutation.isLoading}
              onClick={() => click(getStartDate(startDate), getEndDate(endDate))}
              testId={`${testIdPrefix}Export`}
              disabled={isEmptySalesReport !== null && isEmptySalesReport}
            >
              Export
            </ActionButton>
            <Link ref={linkRef} style={{ display: 'none' }} data-testid={`${testIdPrefix}DownloadLink`}>
              download link
            </Link>
          </Grid>
        </Grid>
      </Paper>
      <SalesReportListing
        data={data}
        isFetching={isFetching}
        showSkeleton={showSkeleton}
        isEmptySalesReport={isEmptySalesReport}
        handlePaginationChanges={handlePaginationChanges}
        isPreviousData={isPreviousData}
        page={page}
        pageSize={pageSize}
      />
    </Box>
  );
};
